define('ivy-tabs/components/ivy-tabs', ['exports', 'ivy-tabs/templates/components/ivy-tabs'], function (exports, _ivyTabsTemplatesComponentsIvyTabs) {
  'use strict';

  /**
   * @module ivy-tabs
   */

  /**
   * @class IvyTabsComponent
   * @namespace IvyTabs
   * @extends Ember.Component
   */
  exports['default'] = Ember.Component.extend({
    classNames: ['ivy-tabs'],

    layout: _ivyTabsTemplatesComponentsIvyTabs['default'],

    /**
     * Registers the `ivy-tabs-tablist` instance.
     *
     * @method registerTabList
     * @param {IvyTabs.IvyTabListComponent} tabList
     */
    registerTabList: function registerTabList(tabList) {
      this.set('tabList', tabList);
    },

    /**
     * Adds a panel to the `tabPanels` array.
     *
     * @method registerTabPanel
     * @param {IvyTabs.IvyTabPanelComponent} tabPanel
     */
    registerTabPanel: function registerTabPanel(tabPanel) {
      this.get('tabPanels').pushObject(tabPanel);
    },

    /**
     * Set this to the model of the tab you'd like to be selected. Usually it is
     * bound to a controller property that is used as a query parameter, but can
     * be bound to anything.
     *
     * @property selection
     * @type Object
     */
    selection: null,

    tabPanels: Ember.computed(function () {
      return Ember.A();
    }).readOnly(),

    /**
     * Removes the `ivy-tabs-tablist` component.
     *
     * @method unregisterTabList
     * @param {IvyTabs.IvyTabListComponent} tabList
     */
    unregisterTabList: function unregisterTabList() /* tabList */{
      this.set('tabList', null);
    },

    /**
     * Removes a panel from the `tabPanels` array.
     *
     * @method unregisterTabPanel
     * @param {IvyTabs.IvyTabPanelComponent} tabPanel
     */
    unregisterTabPanel: function unregisterTabPanel(tabPanel) {
      this.get('tabPanels').removeObject(tabPanel);
    }
  });
});