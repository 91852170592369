define("ember-scrollable/templates/components/ember-scrollable", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 15,
              "column": 0
            }
          },
          "moduleName": "modules/ember-scrollable/templates/components/ember-scrollable.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "ember-scrollbar", [], ["handleOffset", ["subexpr", "@mut", [["get", "horizontalHandleOffset", ["loc", [null, [4, 17], [4, 39]]]]], [], []], "handleSize", ["subexpr", "@mut", [["get", "horizontalHandleSize", ["loc", [null, [5, 15], [5, 35]]]]], [], []], "horizontal", true, "showHandle", ["subexpr", "@mut", [["get", "showHandle", ["loc", [null, [7, 15], [7, 25]]]]], [], []], "isDragging", ["subexpr", "@mut", [["get", "isHorizontalDragging", ["loc", [null, [8, 15], [8, 35]]]]], [], []], "mouseOffset", ["subexpr", "@mut", [["get", "horizontalMouseOffset", ["loc", [null, [9, 16], [9, 37]]]]], [], []], "onJumpTo", ["subexpr", "action", ["horizontalJumpTo"], [], ["loc", [null, [10, 13], [10, 40]]]], "onDrag", ["subexpr", "action", ["horizontalDrag"], [], ["loc", [null, [11, 11], [11, 36]]]], "onDragStart", ["subexpr", "action", ["horizontalDragBoundary", true], [], ["loc", [null, [12, 16], [12, 54]]]], "onDragEnd", ["subexpr", "action", ["horizontalDragBoundary", false], [], ["loc", [null, [13, 14], [13, 53]]]]], ["loc", [null, [3, 2], [14, 4]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 0
            },
            "end": {
              "line": 29,
              "column": 0
            }
          },
          "moduleName": "modules/ember-scrollable/templates/components/ember-scrollable.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "ember-scrollbar", [], ["handleOffset", ["subexpr", "@mut", [["get", "verticalHandleOffset", ["loc", [null, [18, 17], [18, 37]]]]], [], []], "handleSize", ["subexpr", "@mut", [["get", "verticalHandleSize", ["loc", [null, [19, 15], [19, 33]]]]], [], []], "horizontal", false, "showHandle", ["subexpr", "@mut", [["get", "showHandle", ["loc", [null, [21, 15], [21, 25]]]]], [], []], "isDragging", ["subexpr", "@mut", [["get", "isVerticalDragging", ["loc", [null, [22, 15], [22, 33]]]]], [], []], "mouseOffset", ["subexpr", "@mut", [["get", "verticalMouseOffset", ["loc", [null, [23, 16], [23, 35]]]]], [], []], "onJumpTo", ["subexpr", "action", ["verticalJumpTo"], [], ["loc", [null, [24, 13], [24, 38]]]], "onDrag", ["subexpr", "action", ["verticalDrag"], [], ["loc", [null, [25, 11], [25, 34]]]], "onDragStart", ["subexpr", "action", ["verticalBoundaryEvent", true], [], ["loc", [null, [26, 16], [26, 53]]]], "onDragEnd", ["subexpr", "action", ["verticalBoundaryEvent", false], [], ["loc", [null, [27, 14], [27, 52]]]]], ["loc", [null, [17, 2], [28, 4]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 0
            },
            "end": {
              "line": 44,
              "column": 0
            }
          },
          "moduleName": "modules/ember-scrollable/templates/components/ember-scrollable.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tse-content scrollable-content");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "yield", [["subexpr", "hash", [], ["recalculate", ["subexpr", "action", ["recalculate"], [], ["loc", [null, [39, 26], [39, 48]]]], "update", ["subexpr", "action", ["update"], [], ["loc", [null, [40, 21], [40, 38]]]], "scrollTop", ["subexpr", "action", ["scrollTop"], [], ["loc", [null, [41, 24], [41, 44]]]]], ["loc", [null, [38, 12], [42, 13]]]]], [], ["loc", [null, [38, 4], [42, 15]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 45,
            "column": 0
          }
        },
        "moduleName": "modules/ember-scrollable/templates/components/ember-scrollable.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "resize-detector", [["subexpr", "concat", ["#", ["get", "elementId", ["loc", [null, [1, 30], [1, 39]]]]], [], ["loc", [null, [1, 18], [1, 40]]]]], ["on-resize", ["subexpr", "action", ["recalculate"], [], ["loc", [null, [1, 51], [1, 73]]]]], ["loc", [null, [1, 0], [1, 75]]]], ["block", "if", [["get", "horizontal", ["loc", [null, [2, 6], [2, 16]]]]], [], 0, null, ["loc", [null, [2, 0], [15, 7]]]], ["block", "if", [["get", "vertical", ["loc", [null, [16, 6], [16, 14]]]]], [], 1, null, ["loc", [null, [16, 0], [29, 7]]]], ["block", "scroll-content-element", [], ["height", ["subexpr", "@mut", [["get", "scrollContentHeight", ["loc", [null, [31, 9], [31, 28]]]]], [], []], "width", ["subexpr", "@mut", [["get", "scrollContentWidth", ["loc", [null, [32, 8], [32, 26]]]]], [], []], "scrollToX", ["subexpr", "@mut", [["get", "scrollToX", ["loc", [null, [33, 12], [33, 21]]]]], [], []], "scrollToY", ["subexpr", "@mut", [["get", "scrollToY", ["loc", [null, [34, 12], [34, 21]]]]], [], []], "onScroll", ["subexpr", "action", ["scrolled"], [], ["loc", [null, [35, 11], [35, 30]]]]], 2, null, ["loc", [null, [30, 0], [44, 27]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});