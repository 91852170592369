define("ember-light-table/templates/components/lt-foot", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 8
              },
              "end": {
                "line": 8,
                "column": 8
              }
            },
            "moduleName": "modules/ember-light-table/templates/components/lt-foot.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1, "class", "lt-scaffolding");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'style');
            return morphs;
          },
          statements: [["attribute", "style", ["subexpr", "html-safe", [["subexpr", "if", [["get", "column.width", ["loc", [null, [7, 36], [7, 48]]]], ["subexpr", "concat", ["width: ", ["get", "column.width", ["loc", [null, [7, 67], [7, 79]]]]], [], ["loc", [null, [7, 49], [7, 80]]]]], [], ["loc", [null, [7, 32], [7, 81]]]]], [], ["loc", [null, [7, 20], [7, 83]]]]]],
          locals: ["column"],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 6
              },
              "end": {
                "line": 12,
                "column": 6
              }
            },
            "moduleName": "modules/ember-light-table/templates/components/lt-foot.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "yield", [["get", "columns", ["loc", [null, [11, 16], [11, 23]]]]], [], ["loc", [null, [11, 8], [11, 25]]]]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 10
                },
                "end": {
                  "line": 26,
                  "column": 10
                }
              },
              "moduleName": "modules/ember-light-table/templates/components/lt-foot.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "component", [["subexpr", "concat", ["light-table/columns/", ["get", "column.type", ["loc", [null, [15, 55], [15, 66]]]]], [], ["loc", [null, [15, 24], [15, 67]]]], ["get", "column", ["loc", [null, [15, 68], [15, 74]]]]], ["table", ["subexpr", "@mut", [["get", "table", ["loc", [null, [16, 20], [16, 25]]]]], [], []], "tableActions", ["subexpr", "@mut", [["get", "tableActions", ["loc", [null, [17, 27], [17, 39]]]]], [], []], "extra", ["subexpr", "@mut", [["get", "extra", ["loc", [null, [18, 20], [18, 25]]]]], [], []], "sortIcons", ["subexpr", "@mut", [["get", "sortIcons", ["loc", [null, [19, 24], [19, 33]]]]], [], []], "resizeOnDrag", ["subexpr", "@mut", [["get", "resizeOnDrag", ["loc", [null, [20, 27], [20, 39]]]]], [], []], "click", ["subexpr", "action", ["onColumnClick", ["get", "column", ["loc", [null, [21, 44], [21, 50]]]]], [], ["loc", [null, [21, 20], [21, 51]]]], "doubleClick", ["subexpr", "action", ["onColumnDoubleClick", ["get", "column", ["loc", [null, [22, 56], [22, 62]]]]], [], ["loc", [null, [22, 26], [22, 63]]]], "onColumnResized", ["subexpr", "action", ["onColumnResized"], [], ["loc", [null, [23, 30], [23, 56]]]], "onColumnDrag", ["subexpr", "action", ["onColumnDrag"], [], ["loc", [null, [24, 27], [24, 50]]]], "onColumnDrop", ["subexpr", "action", ["onColumnDrop"], [], ["loc", [null, [25, 27], [25, 50]]]]], ["loc", [null, [15, 12], [25, 52]]]]],
            locals: ["column"],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 6
              },
              "end": {
                "line": 28,
                "column": 6
              }
            },
            "moduleName": "modules/ember-light-table/templates/components/lt-foot.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "columns", ["loc", [null, [14, 18], [14, 25]]]]], [], 0, null, ["loc", [null, [14, 10], [26, 19]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 31,
              "column": 0
            }
          },
          "moduleName": "modules/ember-light-table/templates/components/lt-foot.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tfoot");
          dom.setAttribute(el2, "class", "lt-foot");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          dom.setAttribute(el3, "class", "lt-scaffolding-row");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [2]), 1, 1);
          morphs[2] = dom.createMorphAt(element2, 4, 4);
          return morphs;
        },
        statements: [["attribute", "class", ["get", "tableClassNames", ["loc", [null, [2, 17], [2, 32]]]]], ["block", "each", [["get", "columns", ["loc", [null, [6, 16], [6, 23]]]]], [], 0, null, ["loc", [null, [6, 8], [8, 17]]]], ["block", "if", [["get", "hasBlock", ["loc", [null, [10, 12], [10, 20]]]]], [], 1, 2, ["loc", [null, [10, 6], [28, 13]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 32,
            "column": 0
          }
        },
        "moduleName": "modules/ember-light-table/templates/components/lt-foot.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "ember-wormhole", [], ["to", ["subexpr", "concat", [["get", "tableId", ["loc", [null, [1, 29], [1, 36]]]], "_inline_foot"], [], ["loc", [null, [1, 21], [1, 52]]]], "renderInPlace", ["subexpr", "@mut", [["get", "renderInPlace", ["loc", [null, [1, 67], [1, 80]]]]], [], []]], 0, null, ["loc", [null, [1, 0], [31, 19]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});