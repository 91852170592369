define('ember-awesome-macros/promise/-utils', ['exports', 'ember-macro-helpers'], function (exports, _emberMacroHelpers) {
  'use strict';

  exports.wrapPromiseProxy = wrapPromiseProxy;

  function wrapPromiseProxy(Proxy) {
    var PromiseProxy = Proxy.extend(Ember.PromiseProxyMixin);

    return (0, _emberMacroHelpers.curriedComputed)(function (promise) {
      if (promise === undefined) {
        promise = Ember.RSVP.resolve(undefined);
      }

      return PromiseProxy.create({
        promise: promise
      });
    });
  }
});