define('ember-awesome-macros/array/group-by', ['exports', 'ember-awesome-macros/array/-utils'], function (exports, _emberAwesomeMacrosArrayUtils) {
  'use strict';

  function getCurrentGroup(groups, value, comparator) {
    if (comparator) {
      return groups.find(function (group) {
        return comparator(Ember.get(group, 'value'), value);
      });
    }

    return groups.findBy('value', value);
  }

  exports['default'] = (0, _emberAwesomeMacrosArrayUtils.normalizeArray3)({
    firstDefault: function firstDefault() {
      return [];
    },
    func: function func(array, key, comparator) {
      var groups = Ember.A();
      array.forEach(function (item) {
        var value = Ember.get(item, key);
        var currentGroup = getCurrentGroup(groups, value, comparator);

        if (currentGroup) {
          currentGroup.items.push(item);
        } else {
          groups.push({
            key: key,
            value: value,
            items: [item]
          });
        }
      });

      return groups;
    }
  });
});