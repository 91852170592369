define('ember-tooltips/components/ember-popover', ['exports', 'ember-tooltips/components/ember-tooltip-base'], function (exports, _emberTooltipsComponentsEmberTooltipBase) {
  'use strict';

  exports['default'] = _emberTooltipsComponentsEmberTooltipBase['default'].extend({
    popoverHideDelay: 250,
    tooltipClassName: 'ember-popover',

    hideDelay: Ember.computed.deprecatingAlias('popoverHideDelay', {
      id: 'EmberTooltipBase.popoverHideDelay',
      until: '3.2.0'
    }),

    _isMouseInside: false,

    actions: {
      hide: function hide() {
        this.set('_isMouseInside', false);
        this.hide();
      }
    },

    addTargetEventListeners: function addTargetEventListeners() {
      this.addTooltipTargetEventListeners();
      this.addPopoverTargetEventListeners();
    },
    addTooltipBaseEventListeners: function addTooltipBaseEventListeners() {
      var _this = this;

      var _getProperties = this.getProperties('target', '_tooltip'),
          target = _getProperties.target,
          _tooltip = _getProperties._tooltip;

      this.addPopoverEventListeners();

      /* If the user clicks outside the popover, hide the popover. */

      this._addEventListener('click', function (event) {
        var eventTarget = event.target;

        var clickIsOnPopover = eventTarget == _tooltip.popperInstance.popper;
        var clickIsOnTarget = eventTarget == target;

        if (!_this.get('_isMouseInside') && !clickIsOnPopover && !clickIsOnTarget) {
          _this.hide();
        }
      }, document);
    },
    addPopoverTargetEventListeners: function addPopoverTargetEventListeners() {
      var _this2 = this;

      /* We must use mouseover because it correctly
      registers hover interactivity when spacing='0'
      */

      this._addEventListener('mouseenter', function () {
        _this2.set('_isMouseInside', true);
      });

      this._addEventListener('mouseleave', function () {
        _this2.set('_isMouseInside', false);
      });

      this._addEventListener('focusout', function () {
        if (!_this2.get('_isMouseInside')) {
          _this2.hide();
        }
      });
    },
    addPopoverEventListeners: function addPopoverEventListeners() {
      var _this3 = this;

      var _tooltip = this.get('_tooltip');
      var popover = _tooltip.popperInstance.popper;

      /* We must use mouseover because it correctly
      registers hover interactivity when spacing='0'
      */

      this._addEventListener('mouseenter', function () {
        _this3.set('_isMouseInside', true);

        if (_this3.get('showOn') === 'mouseenter' && !_this3.get('isShown')) {
          _this3.show();
        }
      }, popover);

      this._addEventListener('mouseleave', function () {
        _this3.set('_isMouseInside', false);

        if (_this3.get('hideOn') === 'mouseleave' && _this3.get('isShown')) {
          _this3.hide();
        }
      }, popover);

      this._addEventListener('focusout', function () {
        if (!_this3.get('_isMouseInside')) {
          _this3.hide();
        }
      }, popover);
    },
    hide: function hide() {
      var _this4 = this;

      if (this.get('isDestroying') || !this.get('isShown')) {
        return;
      }

      /* If the tooltip is about to be showed by
      a delay, stop is being shown. */

      Ember.run.cancel(this.get('_showTimer'));

      Ember.run.later(function () {
        if (!_this4.get('_isMouseInside')) {
          _this4._hideTooltip();
        }
      }, +this.get('popoverHideDelay'));
    }
  });
});