define("ember-can/computed", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  var get = _ember["default"].get;
  var set = _ember["default"].set;

  exports["default"] = {
    ability: function ability(type, resourceName) {
      if (arguments.length === 1) {
        resourceName = type;
      }

      return _ember["default"].computed(resourceName, function () {
        var container = this.container;
        var ability = container.lookup("ability:" + type);

        _ember["default"].assert("No ability class found for " + type, ability);

        var resource = get(this, resourceName);
        set(ability, "model", resource);
        return ability;
      });
    }
  };
});