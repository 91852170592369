define('ember-awesome-macros/promise/hash', ['exports', 'ember-macro-helpers', 'ember-awesome-macros/hash'], function (exports, _emberMacroHelpers, _emberAwesomeMacrosHash) {
  'use strict';

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports['default'] = function () {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var _deconstructArgs = (0, _emberAwesomeMacrosHash.deconstructArgs)(args),
        hashKeys = _deconstructArgs.hashKeys,
        hashValues = _deconstructArgs.hashValues;

    return (0, _emberMacroHelpers.curriedComputed)(function () {
      for (var _len2 = arguments.length, newValues = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        newValues[_key2] = arguments[_key2];
      }

      var newHash = (0, _emberAwesomeMacrosHash.reduceValues)(hashKeys, newValues);
      return Ember.RSVP.hash(newHash);
    }).apply(undefined, _toConsumableArray(hashValues));
  };
});