define('ember-awesome-macros/collect', ['exports', 'ember-macro-helpers'], function (exports, _emberMacroHelpers) {
  'use strict';

  exports['default'] = (0, _emberMacroHelpers.curriedComputed)(function () {
    for (var _len = arguments.length, values = Array(_len), _key = 0; _key < _len; _key++) {
      values[_key] = arguments[_key];
    }

    return Ember.A(values);
  });
});