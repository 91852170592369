define('ember-tooltips/components/ember-tooltip-base', ['exports', 'ember-tooltips/templates/components/ember-tooltip-base'], function (exports, _emberTooltipsTemplatesComponentsEmberTooltipBase) {
  'use strict';

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }return arr2;
    } else {
      return Array.from(arr);
    }
  } /* global Tooltip */

  var ANIMATION_CLASS = 'ember-tooltip-show';

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.substring(1);
  }

  function getOppositeSide(side) {
    var oppositeSide = void 0;

    switch (side) {
      case 'top':
        oppositeSide = 'bottom';
        break;
      case 'right':
        oppositeSide = 'left';
        break;
      case 'bottom':
        oppositeSide = 'top';
        break;
      case 'left':
        oppositeSide = 'right';
        break;
    }

    return oppositeSide;
  }

  function cleanNumber(stringOrNumber) {
    var cleanNumber = void 0;

    if (stringOrNumber && typeof stringOrNumber === 'string') {
      cleanNumber = parseInt(stringOrNumber, 10);

      /* Remove invalid parseInt results */

      if (isNaN(cleanNumber) || !isFinite(cleanNumber)) {
        cleanNumber = 0;
      }
    } else {
      cleanNumber = stringOrNumber;
    }

    return cleanNumber;
  }

  exports['default'] = Ember.Component.extend({
    classNames: ['ember-tooltip-base'],
    delay: 0,
    delayOnChange: true,
    duration: 0,
    effect: 'slide', // Options: fade, slide, none // TODO - make slide work
    enableFlip: true, // TODO - document
    event: 'hover', // Options: hover, click, focus, none
    tooltipClassName: 'ember-tooltip', /* Custom classes */
    isShown: false,
    text: null,
    side: 'top',
    spacing: 10,
    targetId: null,
    layout: _emberTooltipsTemplatesComponentsEmberTooltipBase['default'],
    updateFor: null,

    /* Actions */

    onDestroy: null,
    onHide: null,
    onRender: null,
    onShow: null,

    hideOn: Ember.computed('event', function () {
      var event = this.get('event');

      var hideOn = void 0;

      switch (event) {
        case 'hover':
          hideOn = 'mouseleave';
          break;
        case 'focus':
          hideOn = 'blur';
          break;
        case 'ready':
          hideOn = null;
          break;
        default:
          hideOn = event;
          break;
      }

      return hideOn;
    }),

    showOn: Ember.computed('event', function () {
      var event = this.get('event');

      var showOn = void 0;

      switch (event) {
        case 'hover':
          showOn = 'mouseenter';
          break;
        default:
          showOn = event;
          break;
      }

      return showOn;
    }),

    target: Ember.computed('targetId', function () {
      var targetId = this.get('targetId');

      var target = void 0;

      if (targetId) {
        target = document.getElementById(targetId);

        if (!target) {
          true && Ember.warn('No target found for targetId ', targetId, {
            id: 'ember-tooltips.no-element-with-targetId'
          });
        }
      } else {
        target = this.element.parentNode;
      }

      return target;
    }),

    /* An ID used to identify this tooltip from other tooltips */

    wormholeId: Ember.computed('elementId', function () {
      return this.get('elementId') + '-wormhole';
    }),

    _animationDuration: 200, // In ms
    _awaitingTooltipElementRendered: true,
    _tooltipEvents: null,
    _tooltip: null,
    _spacingRequestId: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('_tooltipEvents', []);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.createTooltip().then(function () {});
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      if (this.get('isShown')) {
        this.show();

        /* If updateFor exists, update the tooltip incase the changed Attr affected the tooltip content's height or width */

        if (this.get('updateFor') && this.get('_tooltip').popperInstance) {
          var popper = this.get('_tooltip').popperInstance;

          if (popper) {
            Ember.run.scheduleOnce('afterRender', popper, popper.update);
          }
        }
      } else {
        this.hide();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.hide();

      var _tooltipEvents = this.get('_tooltipEvents');

      /* Remove event listeners used to show and hide the tooltip */

      _tooltipEvents.forEach(function (tooltipEvent) {
        var callback = tooltipEvent.callback,
            target = tooltipEvent.target,
            eventName = tooltipEvent.eventName;

        target.removeEventListener(eventName, callback);
      });

      this.get('_tooltip').dispose();

      this._dispatchAction('onDestroy', this);
    },
    addTargetEventListeners: function addTargetEventListeners() {
      this.addTooltipTargetEventListeners();
    },
    addTooltipBaseEventListeners: function addTooltipBaseEventListeners() {},
    addTooltipTargetEventListeners: function addTooltipTargetEventListeners() {
      var _this = this;

      /* Setup event handling to hide and show the tooltip */

      var event = this.get('event');

      /* Setup event handling to hide and show the tooltip */

      if (event === 'none') {
        return;
      }

      var hideOn = this.get('hideOn');
      var showOn = this.get('showOn');

      /* If show and hide are the same (e.g. click) toggle
      the visibility */

      if (showOn === hideOn) {
        this._addEventListener(showOn, function () {
          _this.toggle();
        });
      } else {

        /* Else, add the show and hide events individually */

        if (showOn !== 'none') {
          this._addEventListener(showOn, function () {
            _this.show();
          });
        }

        if (hideOn !== 'none') {
          this._addEventListener(hideOn, function () {
            _this.hide();
          });
        }
      }

      /* Hide and show the tooltip on focus and escape
      for accessibility */

      if (event !== 'focus') {

        /* If the event is click, we don't want the
        click to also trigger focusin */

        if (event !== 'click') {
          this._addEventListener('focusin', function () {
            _this.show();
          });
        }

        this._addEventListener('focusout', function () {
          _this.hide();
        });
      }

      this._addEventListener('keydown', function (keyEvent) {

        keyEvent.stopImmediatePropagation(); /* So this callback only fires once per keydown */

        if (keyEvent.which === 27) {
          _this.hide();

          keyEvent.preventDefault();

          return false;
        }
      }, document);
    },
    createTooltip: function createTooltip() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {

        try {
          Ember.run(function () {
            var config = Ember.getOwner(_this2).resolveRegistration('config:environment');
            var rootElement = document.querySelector(config.APP.rootElement);
            var target = _this2.get('target');
            var tooltipClassName = _this2.get('tooltipClassName');
            var tooltip = new Tooltip(target, {
              container: rootElement || false,
              html: true,
              placement: _this2.get('side'),
              title: '<span></span>',
              trigger: 'manual',
              template: '<div class="tooltip ' + tooltipClassName + ' ember-tooltip-effect-' + _this2.get('effect') + '" role="tooltip" style="margin:0;margin-' + getOppositeSide(_this2.get('side')) + ':' + _this2.get('spacing') + 'px;">\n                        <div class="tooltip-arrow ember-tooltip-arrow"></div>\n                        <div class="tooltip-inner" id="' + _this2.get('wormholeId') + '"></div>\n                       </div>',

              popperOptions: {
                modifiers: {
                  flip: {
                    enabled: _this2.get('enableFlip')
                  },
                  preventOverflow: {
                    escapeWithReference: true
                  }
                },

                onCreate: function onCreate(tooltipData) {
                  Ember.run(function () {

                    _this2._dispatchAction('onRender', _this2);

                    _this2.set('_awaitingTooltipElementRendered', false);

                    /* The tooltip element must exist in order to add event listeners to it */

                    _this2.addTooltipBaseEventListeners();

                    /* Once the wormhole has done it's work, we need the tooltip to be positioned again */

                    Ember.run.scheduleOnce('afterRender', function () {
                      var popperInstance = tooltipData.instance;

                      popperInstance.update();
                    });

                    resolve(tooltipData);
                  });
                },

                onUpdate: function onUpdate() {
                  _this2.setSpacing();
                }
              }
            });

            /* Add a class to the tooltip target */

            target.classList.add('ember-tooltip-target');

            _this2.addTargetEventListeners();
            _this2.set('_tooltip', tooltip);

            /* If user passes isShown=true, show the tooltip as soon as it's created */

            if (_this2.get('isShown')) {
              _this2.show();
            }
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    setSpacing: function setSpacing() {
      var _this3 = this;

      if (this._spacingRequestId || !this.get('isShown') || this.get('isDestroying')) {
        return;
      }

      this._spacingRequestId = requestAnimationFrame(function () {
        _this3._spacingRequestId = null;

        var _get = _this3.get('_tooltip'),
            popperInstance = _get.popperInstance;

        var popper = popperInstance.popper;

        var marginSide = getOppositeSide(popper.getAttribute('x-placement'));
        var style = popper.style;

        style.marginTop = 0;
        style.marginRight = 0;
        style.marginBottom = 0;
        style.marginLeft = 0;

        popper.style['margin' + capitalize(marginSide)] = _this3.get('spacing') + 'px';
      });
    },
    hide: function hide() {

      if (this.get('isDestroying')) {
        return;
      }

      /* If the tooltip is about to be showed by
      a delay, stop is being shown. */

      Ember.run.cancel(this.get('_showTimer'));

      this._hideTooltip();
    },
    show: function show() {

      if (this.get('isDestroying')) {
        return;
      }

      var delay = this.get('delay');
      var duration = this.get('duration');

      Ember.run.cancel(this.get('_showTimer'));

      if (duration) {
        this.setHideTimer(duration);
      }

      if (delay) {
        this.setShowTimer(delay);
      } else {
        this._showTooltip();
      }
    },
    setHideTimer: function setHideTimer(duration) {
      duration = cleanNumber(duration);

      Ember.run.cancel(this.get('_hideTimer'));

      if (duration) {

        /* Hide tooltip after specified duration */

        var hideTimer = Ember.run.later(this, this.hide, duration);

        /* Save timer ID for canceling should an event
        hide the tooltip before the duration */

        this.set('_hideTimer', hideTimer);
      }
    },
    setShowTimer: function setShowTimer(delay) {
      var _this4 = this;

      delay = cleanNumber(delay);

      if (!this.get('delayOnChange')) {

        /* If the `delayOnChange` property is set to false, we
        don't want to delay opening this tooltip/popover if there is
        already a tooltip/popover shown in the DOM. Check that here
        and adjust the delay as needed. */

        var shownTooltipsOrPopovers = Ember.$('.' + ANIMATION_CLASS);

        if (shownTooltipsOrPopovers.length) {
          delay = 0;
        }
      }

      var _showTimer = Ember.run.later(this, function () {
        _this4._showTooltip();
      }, delay);

      this.set('_showTimer', _showTimer);
    },
    _hideTooltip: function _hideTooltip() {
      var _this5 = this;

      var _tooltip = this.get('_tooltip');

      if (!_tooltip || this.get('isDestroying')) {
        return;
      }

      if (_tooltip.popperInstance) {
        _tooltip.popperInstance.popper.classList.remove(ANIMATION_CLASS);
      }

      cancelAnimationFrame(this._spacingRequestId);

      Ember.run.later(function () {

        if (_this5.get('isDestroying')) {
          return;
        }

        _tooltip.hide();

        _this5.set('_isHiding', false);
        _this5.set('isShown', false);
        _this5._dispatchAction('onHide', _this5);
      }, this.get('_animationDuration'));
    },
    _showTooltip: function _showTooltip() {
      var _this6 = this;

      if (this.get('isDestroying')) {
        return;
      }

      var _tooltip = this.get('_tooltip');

      _tooltip.show();

      this.set('isShown', true);

      Ember.run(function () {
        if (_this6.get('isDestroying')) {
          return;
        }

        _tooltip.popperInstance.popper.classList.add(ANIMATION_CLASS);

        _this6._dispatchAction('onShow', _this6);
      });
    },
    toggle: function toggle() {

      /* We don't use toggleProperty because we centralize
      logic for showing and hiding in the show() and hide()
      methods. */

      if (this.get('isShown')) {
        this.hide();
      } else {
        this.show();
      }
    },
    _addEventListener: function _addEventListener(eventName, callback, element) {
      var target = element || this.get('target');

      /* Remember event listeners so they can removed on teardown */

      var boundCallback = Ember.run.bind(this, callback);

      this.get('_tooltipEvents').push({
        callback: boundCallback,
        target: target,
        eventName: eventName
      });

      /* Add the event listeners */

      target.addEventListener(eventName, boundCallback);
    },
    _dispatchAction: function _dispatchAction(actionName) {
      var action = this.get(actionName);

      if (!this.isDestroying && !this.isDestroyed && action) {
        for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        action.apply(undefined, _toConsumableArray(args));
      }
    }
  });
});