define('ember-can/helpers/cannot', ['exports', 'ember', 'ember-can/helpers/can'], function (exports, _ember, _emberCanHelpersCan) {
  'use strict';

  exports['default'] = _ember['default'].Helper.extend({
    helper: _ember['default'].computed(function () {
      var container = this.container;
      return _emberCanHelpersCan['default'].create({ container: container });
    }),

    compute: function compute(params, hash) {
      return !this.get('helper').compute(params, hash);
    }
  });
});