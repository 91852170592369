define('ember-awesome-macros/string/-utils', ['exports', 'ember-macro-helpers'], function (exports, _emberMacroHelpers) {
  'use strict';

  exports.normalizeString = normalizeString;
  exports.normalizeString2 = normalizeString2;

  function normalizeString(func) {
    return (0, _emberMacroHelpers.curriedComputed)(function (val) {
      if (!val) {
        return val;
      }

      return func(val);
    });
  }

  function normalizeString2(func) {
    var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};

    return (0, _emberMacroHelpers.lazyCurriedComputed)(function (get, stringKey) {
      for (var _len = arguments.length, keys = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        keys[_key - 2] = arguments[_key];
      }

      var stringVal = get(stringKey);
      if (stringVal === undefined) {
        return defaultValue();
      }

      var prop = stringVal[func];
      if (typeof prop === 'function') {
        return prop.apply(stringVal, keys.map(get));
      }

      return prop;
    });
  }
});