define("ember-cli-string-helpers/helpers/w", ["exports"], function (exports) {
  "use strict";

  exports.w = w;
  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  function w(_ref) {
    var _ref2 = _toArray(_ref),
        wordStrings = _ref2.slice(0);

    return wordStrings.map(Ember.String.w).reduce(function (words, moreWords) {
      return words.concat(moreWords);
    }, []);
  }

  exports["default"] = Ember.Helper.helper(w);
});