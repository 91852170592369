define('ember-moment/computeds/duration', ['exports', 'ember', 'ember-new-computed', 'moment', 'ember-moment/utils/is-descriptor'], function (exports, _ember, _emberNewComputed, _moment, _emberMomentUtilsIsDescriptor) {
  'use strict';

  var _get = _ember['default'].get;

  function computedDuration(val, maybeUnits) {
    var numArgs = arguments.length;
    var args = [val];

    var computed = (0, _emberNewComputed['default'])(val, {
      get: function get() {
        var momentArgs = [_get(this, val)];

        if (numArgs > 1) {
          var desc = _emberMomentUtilsIsDescriptor['default'].call(this, maybeUnits);
          var input = desc ? _get(this, maybeUnits) : maybeUnits;

          if (desc && computed._dependentKeys.indexOf(maybeUnits) === -1) {
            computed.property(maybeUnits);
          }

          momentArgs.push(input);
        }

        return _moment['default'].duration.apply(this, momentArgs).humanize();
      }
    });

    return computed.property.apply(computed, args);
  }

  exports['default'] = computedDuration;
});