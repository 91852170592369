define('ember-web-api/serializers/web-api', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  'use strict';

  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var _this = this;

      // If the response is empty, return the appropriate JSON API response.
      // Unfortunately, this._super apparently doesn't support this condition properly.
      // Based on the documentation at: http://jsonapi.org/format/
      if (payload === null) {
        return { data: null };
      }

      var payloadWithRoot = {},
          isCollection = payload.length > 0,
          key = isCollection ? _ember['default'].Inflector.inflector.pluralize(primaryModelClass.modelName) : primaryModelClass.modelName;

      payloadWithRoot[key] = payload;

      if (isCollection) {
        payload.forEach(function (item) {
          _this._extractRelationships(store, payloadWithRoot, item, primaryModelClass);
          item.attributes = _ember['default'].copy(item, true);
          if (item.type) {
            item.type = primaryModelClass.modelName;
          }
          delete item.attributes.id;
        });
      } else {
        this._extractRelationships(store, payloadWithRoot, payload, primaryModelClass);
        payload.attributes = _ember['default'].copy(payload, true);
        if (payload.type) {
          payload.type = primaryModelClass.modelName;
        }
        delete payload.attributes.id;
      }

      return this._super(store, primaryModelClass, payloadWithRoot, id, requestType);
    },

    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var _this2 = this;

      var key = this.payloadKeyFromModelName(relationship.key);
      if (this._shouldSerializeHasMany(snapshot, key, relationship)) {
        json[key] = [];

        snapshot.hasMany(relationship.key).forEach(function (i) {
          json[key].push(_this2.serialize(i, { includeId: true }));
        });
      }
    },

    serializeIntoHash: function serializeIntoHash(json, typeClass, snapshot, options) {
      if (!options) {
        options = { includeId: true };
      } else {
        options.includeId = true;
      }

      var serialized = this.serialize(snapshot, options),
          prop;

      for (prop in serialized) {
        if (serialized.hasOwnProperty(prop)) {
          json[prop] = serialized[prop];
        }
      }
    },

    sideloadItem: function sideloadItem(store, payload, type, record) {
      if (!(record instanceof Object)) {
        return false;
      }

      var key = _ember['default'].Inflector.inflector.pluralize(type.modelName),
          arr = payload[key] || _ember['default'].A([]),
          pk = store.serializerFor(type.modelName).primaryKey,
          id = record[pk];

      if (typeof arr.findBy(pk, id) !== 'undefined') {
        return true;
      }

      record.attributes = _ember['default'].copy(record, true);
      record.type = type.modelName;
      delete record.attributes.id;

      arr.push(record);
      payload[key] = arr;
      return true;
    },

    _extractRelationships: function _extractRelationships(store, payload, record, type) {
      var _this3 = this;

      type.eachRelationship(function (key, relationship) {
        var relatedRecord = record[key];

        if (relatedRecord) {
          (function () {
            var relationshipType = typeof relationship.type === 'string' ? store.modelFor(relationship.type) : relationship.type;
            if (relationship.kind === 'belongsTo') {
              _this3.sideloadItem(store, payload, relationshipType, relatedRecord);
              record[key] = relatedRecord[store.serializerFor(relationshipType.modelName).primaryKey];
              _this3._extractRelationships(store, payload, relatedRecord, relationshipType);
            } else if (relationship.kind === 'hasMany') {
              relatedRecord.forEach(function (item, index) {
                if (_this3.sideloadItem(store, payload, relationshipType, item)) {
                  relatedRecord[index] = item[store.serializerFor(relationshipType.modelName).primaryKey];
                }
                _this3._extractRelationships(store, payload, item, relationshipType);
              });
            }
          })();
        }
      });
    }
  });
});