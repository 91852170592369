define('ember-multiselect-checkboxes/components/multiselect-checkboxes', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Checkbox = _ember['default'].Object.extend({
    isSelected: _ember['default'].computed('value', 'selection', {
      get: function get() {
        return this.get('selection').contains(this.get('value'));
      },

      set: function set(_, checked) {
        var selected = this.get('selection').contains(this.get('value'));

        if (checked && !selected) {
          this.get('selection').addObject(this.get('value'));
        } else if (!checked && selected) {
          this.get('selection').removeObject(this.get('value'));
        }

        return checked;
      }
    })
  });

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['multiselect-checkboxes'],

    tagName: 'ul',

    options: _ember['default'].A(),

    selection: _ember['default'].A(),

    labelProperty: null,

    valueProperty: null,

    disabled: false,

    checkboxes: _ember['default'].computed('options.[]', 'labelProperty', 'valueProperty', 'selection.[]', function () {
      var labelProperty = this.get('labelProperty');
      var valueProperty = this.get('valueProperty');
      var selection = _ember['default'].A(this.get('selection'));

      var checkboxes = this.get('options').map(function (option) {
        var label = undefined,
            value = undefined;

        if (labelProperty) {
          if (typeof option.get === 'function') {
            label = option.get(labelProperty);
          } else {
            label = option[labelProperty];
          }
        } else {
          label = String(option);
        }

        if (valueProperty) {
          if (typeof option.get === 'function') {
            value = option.get(valueProperty);
          } else {
            value = option[valueProperty];
          }
        } else {
          value = option;
        }

        return Checkbox.create({
          option: option,
          label: label,
          value: value,
          selection: selection
        });
      });

      return _ember['default'].A(checkboxes);
    })
  });
});