define('ember-awesome-macros/array/is-any', ['exports', 'ember-awesome-macros/array/-utils'], function (exports, _emberAwesomeMacrosArrayUtils) {
  'use strict';

  exports['default'] = (0, _emberAwesomeMacrosArrayUtils.normalizeArray3)({
    firstDefault: function firstDefault() {
      return undefined;
    },
    secondDefault: function secondDefault() {
      return undefined;
    },
    func: 'isAny'
  });
});