define("ember-scrollable/templates/components/ember-scrollbar", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 117
          }
        },
        "moduleName": "modules/ember-scrollable/templates/components/ember-scrollbar.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'onmousedown');
        morphs[2] = dom.createAttrMorph(element0, 'style');
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["drag-handle ", ["subexpr", "if", [["get", "showHandle", ["loc", [null, [1, 29], [1, 39]]]], "visible"], [], ["loc", [null, [1, 24], [1, 51]]]]]]], ["attribute", "onmousedown", ["subexpr", "action", ["startDrag"], [], ["loc", [null, [1, 65], [1, 87]]]]], ["attribute", "style", ["get", "handleStyles", ["loc", [null, [1, 96], [1, 108]]]]]],
      locals: [],
      templates: []
    };
  })());
});