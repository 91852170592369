define('ember-body-class/instance-initializers/body-class', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.initialize = initialize;

  function initialize(instance) {
    var config;
    if (instance.resolveRegistration) {
      // Ember 2.1+
      // http://emberjs.com/blog/2015/08/16/ember-2-1-beta-released.html#toc_registry-and-container-reform
      config = instance.resolveRegistration('config:environment');
    } else {
      config = instance.container.lookupFactory('config:environment');
    }

    // Default to true when not set
    var _includeRouteName = true;
    if (config['ember-body-class'] && config['ember-body-class'].includeRouteName === false) {
      _includeRouteName = false;
    }

    _ember['default'].Route.reopen({
      classNames: [],
      bodyClasses: [], // Backwards compatibility

      _getRouteName: function _getRouteName() {
        var nameParts = this.get('routeName').split('.');
        return nameParts[nameParts.length - 1];
      },

      addClasses: _ember['default'].on('activate', function () {
        var _this = this;

        var $body = _ember['default'].$('body');
        ['bodyClasses', 'classNames'].forEach(function (classes) {
          _this.get(classes).forEach(function (klass) {
            $body.addClass(klass);
          });
        });

        if (_includeRouteName) {
          $body.addClass(this._getRouteName());
        }
      }),

      removeClasses: _ember['default'].on('deactivate', function () {
        var _this2 = this;

        var $body = _ember['default'].$('body');
        ['bodyClasses', 'classNames'].forEach(function (classes) {
          _this2.get(classes).forEach(function (klass) {
            $body.removeClass(klass);
          });
        });

        if (_includeRouteName) {
          $body.removeClass(this._getRouteName());
        }
      })
    });
  }

  exports['default'] = {
    name: 'body-class',
    initialize: initialize
  };
});