define('ember-awesome-macros/promise/then', ['exports', 'ember-macro-helpers'], function (exports, _emberMacroHelpers) {
  'use strict';

  exports['default'] = (0, _emberMacroHelpers.lazyCurriedComputed)(function (getValue, promise, property) {
    promise = getValue(promise);
    if (promise === undefined) {
      return Ember.RSVP.resolve();
    }
    return promise.then(function (x) {
      property = getValue(property);
      if (property !== undefined) {
        return Ember.get(x, property);
      }
    });
  });
});