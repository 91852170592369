define('ember-web-api/adapters/web-api', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  'use strict';

  var VALIDATION_ERROR_STATUSES = [400, 422];

  exports['default'] = _emberData['default'].RESTAdapter.extend({
    namespace: 'api',

    isInvalid: function isInvalid(status) {
      return VALIDATION_ERROR_STATUSES.indexOf(status) >= 0;
    },

    // Override the parseErrorResponse method from RESTAdapter
    // so that we can munge the modelState into an errors collection.
    // The source of the original method can be found at:
    // https://github.com/emberjs/data/blob/v2.1.0/packages/ember-data/lib/adapters/rest-adapter.js#L899
    parseErrorResponse: function parseErrorResponse(responseText) {
      var json = this._super(responseText),
          strippedErrors = {},
          jsonIsObject = json && typeof json === 'object';

      if (jsonIsObject && json.message) {
        delete json.message;
      }

      if (jsonIsObject && json.modelState) {
        Object.keys(json.modelState).forEach(function (key) {
          var newKey = _ember['default'].String.camelize(key.substring(key.indexOf('.') + 1));
          strippedErrors[newKey] = json.modelState[key];
        });

        json.errors = _emberData['default'].errorsHashToArray(strippedErrors);

        delete json.modelState;
      }

      return json;
    }
  });
});