define("ember-awesome-macros/type-of", ["exports", "ember-macro-helpers"], function (exports, _emberMacroHelpers) {
  "use strict";

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports["default"] = (0, _emberMacroHelpers.curriedComputed)(function (object) {
    return typeof object === 'undefined' ? 'undefined' : _typeof(object);
  });
});