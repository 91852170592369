define('ember-awesome-macros/promise/index', ['exports', 'ember-awesome-macros/promise/all', 'ember-awesome-macros/promise/array', 'ember-awesome-macros/promise/hash', 'ember-awesome-macros/promise/object', 'ember-awesome-macros/promise/resolve', 'ember-awesome-macros/promise/then'], function (exports, _emberAwesomeMacrosPromiseAll, _emberAwesomeMacrosPromiseArray, _emberAwesomeMacrosPromiseHash, _emberAwesomeMacrosPromiseObject, _emberAwesomeMacrosPromiseResolve, _emberAwesomeMacrosPromiseThen) {
  'use strict';

  Object.defineProperty(exports, 'all', {
    enumerable: true,
    get: function get() {
      return _emberAwesomeMacrosPromiseAll['default'];
    }
  });
  Object.defineProperty(exports, 'array', {
    enumerable: true,
    get: function get() {
      return _emberAwesomeMacrosPromiseArray['default'];
    }
  });
  Object.defineProperty(exports, 'hash', {
    enumerable: true,
    get: function get() {
      return _emberAwesomeMacrosPromiseHash['default'];
    }
  });
  Object.defineProperty(exports, 'object', {
    enumerable: true,
    get: function get() {
      return _emberAwesomeMacrosPromiseObject['default'];
    }
  });
  Object.defineProperty(exports, 'resolve', {
    enumerable: true,
    get: function get() {
      return _emberAwesomeMacrosPromiseResolve['default'];
    }
  });
  Object.defineProperty(exports, 'then', {
    enumerable: true,
    get: function get() {
      return _emberAwesomeMacrosPromiseThen['default'];
    }
  });
});